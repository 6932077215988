import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import dayjs from 'dayjs';

// import SaveButton from '../../components/buttons/save-button';
import CircleProgress from '../../components/progress/circle';

import isAuthenticated from '../../auth/isAuthenticated';
import GET_ALL_PLU_ITEMS from './queries/listPluTodo';
import EDIT_ITEMS from './mutations/editPluItems';
import cleanUpcPluItemsForMutation from '../utils/cleanUpcPluItemsForMutation';

import MUIDataTable from 'mui-datatables';
import {
  Button,
  createMuiTheme,
  FormControlLabel,
  MuiThemeProvider,
  TextField
} from '@material-ui/core';

import './nacs-button.scss';

// keyIndex,
// shortDescription,
// longDescription,
// nacsCode,
// nacsCodeNote,
// createdDate,
// modifiedDate,
const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          paddingTop: '0',
          paddingBottom: '0'
        }
      }
    }
  });

const options = {
  selectableRows: false,
  setRowProps: () => {
    return {
      className: 'data-row'
    };
  },
  rowsPerPageOptions: ['50', '100'],
  rowsPerPage: 50
};

const updateObjFromRowData = (row) => {
  const updatePluObject = {
    keyIndex: row.keyIndex,
    productGroupCode: row.productGroupCode,
    longDescription: row.longDescription,
    nacsCode: row.nacsCode,
    revisedNacs: row.revisedNacs,
    nacsCodeNote: row.nacsCodeNote
  };

  return updatePluObject;
};

const PLUListComponent = (props) => {
  const { loading, error, data } = useQuery(GET_ALL_PLU_ITEMS);
  const [rows, setRows] = useState([]);
  const [loadingState, setLoading] = useState(false);
  const [mutateUpcItems, { mutatedData = data }] = useMutation(EDIT_ITEMS, {
    refetchQueries: [{ query: GET_ALL_PLU_ITEMS }]
  });

  useEffect(() => {
    if (data && data.getPluTodoItems) {
      setRows(data.getPluTodoItems);
    }
  }, [data]);

  const saveUpdates = async () => {
    setLoading(true);
    const updates = _.filter(rows, (row) => row.revisedNacs);
    const dbUpdates = _.each(updates, (row) => updateObjFromRowData(row));

    // send dbUpdates to API
    const _data = cleanUpcPluItemsForMutation(dbUpdates);
    await mutateUpcItems({
      variables: {
        input: _data
      }
    });
    setRows([...mutatedData.getPluTodoItems]);
    setLoading(false);
  };
  const updateTableData = (keyIndex, value, key) => {
    const row = rows.find((row) => row.keyIndex === keyIndex);
    const rowIndex = rows.findIndex((row) => row.keyIndex === keyIndex);

    row[key] = value;
    rows.splice(rowIndex, 1, row);
    setRows([...rows]);
  };

  if (loading) return <CircleProgress size="1em" />;
  if (error) return <p>{error.message} :(</p>;

  const columns = [
    {
      name: 'createdDate',
      label: 'Created Date',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return dayjs(value).format('MM-DD-YYYY');
        }
      }
    },
    {
      name: 'keyIndex',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'productGroupCode',
      label: 'PLU',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'longDescription',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/search?q=${value}`}
            >
              {value}
            </a>
          );
        }
      }
    },
    {
      name: 'nacsCode',
      label: 'NACS Code',
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowData } = tableMeta;
          return (
            <span>
              {value}
              <Button
                variant="outlined"
                size="small"
                className={'nacs-btn'}
                onClick={(event) => {
                  updateTableData(rowData[1], value, 'revisedNacs');
                }}
              >
                Accept &gt;
              </Button>
            </span>
          );
        }
      }
    },
    {
      name: 'revisedNacs',
      label: 'Revised NACS Code',
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowData } = tableMeta;
          if (!value) value = '';
          let error = false;
          return (
            <FormControlLabel
              name={'revisedNacs'}
              value={value || ''}
              control={<TextField value={value || ''} error={error} />}
              onChange={(event) => {
                if (
                  event.target.value === '' ||
                  (event.target.value.length <= 3 &&
                    /^\d+$/.test(event.target.value))
                ) {
                  updateValue(event.target.value);
                }
              }}
              onBlur={(event) => {
                if (event.target.value.length === 3)
                  updateTableData(
                    rowData[1],
                    event.target.value,
                    'revisedNacs'
                  );
              }}
            />
          );
        }
      }
    },
    {
      name: 'nacsCodeNote',
      label: 'NACS Code Note',
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowData } = tableMeta;
          return (
            <FormControlLabel
              name={'nacsCodeNote'}
              value={value || ''}
              control={
                <TextField value={value || ''} style={{ width: '100%' }} />
              }
              style={{ width: '100%' }}
              onChange={(event) => {
                if (
                  event.target.value.length < 501 &&
                  event.target.value.indexOf('|') === -1
                ) {
                  updateValue(event.target.value);
                }
              }}
              onBlur={(event) => {
                updateTableData(rowData[1], event.target.value, 'nacsCodeNote');
              }}
            />
          );
        }
      }
    }
  ];

  return (
    <Fragment>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          className="plu-table"
          title={'PLU To-Do List'}
          data={rows}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>

      <div className="sticky-footer">
        <Button onClick={saveUpdates} variant="contained" size="large">
          {loadingState ? <CircleProgress size="1em" /> : 'Save'}
        </Button>
      </div>
    </Fragment>
  );
};

export default isAuthenticated(PLUListComponent);
