import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { NavLink } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';

import Can from '../../auth/userCanPerform';
import isAuthenticated from '../../auth/isAuthenticated';
import listItems from './queries/listItems';

import CircleProgress from '../../components/progress/circle';
import ActionButton from '../../components/buttons/action-button';

import './view.css';

const renderSoldTo = (data, tableMeta) => {
  const { rowIndex } = tableMeta;

  const results = [];

  data.forEach((item, index) => {
    results.push(
      <div key={`sold-to-${rowIndex}-${index}`} className="sold-to">
        {item}
      </div>
    );
  });

  return results;
};

const renderContact = (data, tableMeta) => {
  const { rowIndex } = tableMeta;
  const results = [];

  data.forEach((item, index) => {
    results.push(
      <div key={`contact-${rowIndex}-${index}`} className="contacts">
        {item.email}
      </div>
    );
  });

  return results;
};

const renderEdit = (data, tableMeta) => {
  const { rowData, rowIndex } = tableMeta;
  return (
    <div key={`edit-${rowIndex}`} className="edit">
      <NavLink to={`contacts/edit/${rowData[0]}`}>edit</NavLink>
    </div>
  );
};

const tableOptions = {
  download: false,
  print: false,
  search: true,
  selectableRows: 'none',
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100]
};
const columns = [
  {
    name: 'uuid',
    options: {
      display: false,
      filter: false,
      viewColumns: false
    }
  },
  {
    label: 'Name',
    name: 'bw_name',
    options: {
      sort: true,
      sortDirection: 'asc'
    }
  },
  {
    label: 'Sold To',
    name: 'sold_to',
    options: {
      customBodyRender: renderSoldTo,
      sort: false
    }
  },
  {
    label: 'Contacts',
    name: 'contacts',
    options: {
      customBodyRender: renderContact,
      filter: false,
      sort: false
    }
  },
  {
    name: 'Status',
    options: {
      filter: true,
      sort: false,
      viewColumns: false
    }
  },
  {
    name: '',
    options: {
      customBodyRender: renderEdit,
      filter: false,
      viewColumns: false
    }
  }
];

const renderContactList = (props) => {
  const { history, auth, rows } = props;

  return Can({
    groups: auth.groups,
    perform: 'contact-list:view',
    /* eslint-disable-next-line react/display-name */
    no: () => <p>not authorized</p>,
    /* eslint-disable-next-line react/display-name */
    yes: () => {
      return (
        <div>
          <h1>BW Contact List</h1>
          <ActionButton
            label="Add a BW Contact"
            onClick={() => history.push('/contacts/create')}
          />
          <div
            style={{
              width: '100%',
              marginTop: '25px',
              marginBottom: '25px'
            }}
          >
            <MUIDataTable
              options={tableOptions}
              columns={columns}
              data={rows}
            />
          </div>
        </div>
      );
    }
  });
};

const MasterList = (props) => {
  const { auth, history } = props;
  const { loading, error, data } = useQuery(listItems);
  const [rows, setRows] = useState([]);
  const showContactList = renderContactList({ history, auth, rows });

  useEffect(() => {
    if (data && data.getAllBWItems) {
      setRows(data.getAllBWItems);
    }
  }, [data]);

  if (loading) return <CircleProgress size="1em" />;
  if (error) return <p>{error.message} :(</p>;

  return <Fragment>{showContactList}</Fragment>;
};

export default isAuthenticated(MasterList);
