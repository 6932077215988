import React, { useEffect, useReducer } from 'react';
import { Auth } from 'aws-amplify';

import { AuthProvider } from '../contexts/authContext';
import useCookie from './hooks/use-cookie';
import AuthReducer from './reducers/auth';

const initialState = {
  authChallenge: undefined,
  authErrorText: undefined,
  inFlight: false,
  user: {},
  groups: []
};
const _expireCookie = 'Thu, 01 Jan 1970 00:00:00 GMT';

const AppAuth = (props) => {
  const token = localStorage.getItem('token');
  const [authenticated, setAuthenticated] = useCookie('authenticated', !token);
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const { inFlight, user, authErrorText } = state;

  const refreshSession = (cognitoUser, refreshToken) => {
    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err) {
          return reject(err);
        }
        return resolve(session);
      });
    });
  };

  useEffect(() => {
    const checkForAuthenticatedUser = async () => {
      dispatch({ type: 'auth-request' });

      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        await refreshSession(cognitoUser, session.refreshToken);

        return dispatch({ type: 'auth-success', payload: cognitoUser });
      } catch (err) {
        setAuthenticated(false, { expires: _expireCookie });
        const _msg = err.message ? err.message : err;
        return dispatch({ type: 'auth-error', payload: _msg });
      }
    };

    if (!authErrorText && !inFlight && user && !user.username) {
      checkForAuthenticatedUser();
    }
  }, [authErrorText, inFlight, user, authenticated, setAuthenticated]);

  const initiateLogin = async (data, history) => {
    const { email, password } = data;

    dispatch({ type: 'auth-request' });

    try {
      const cognitoUser = await Auth.signIn(email, password);
      const { challengeName } = cognitoUser;

      switch (challengeName) {
        case 'FORCE_CHANGE_PASSWORD':
        case 'PASSWORD_VERIFIER':
        case 'NEW_PASSWORD_REQUIRED': {
          dispatch({ type: 'auth-challenge', payload: cognitoUser });
          return history.push('/change-password');
        }
        default: {
          const { signInUserSession } = cognitoUser;
          const { idToken } = signInUserSession;

          setAuthenticated(true, { expires: idToken.exp });
          dispatch({ type: 'auth-success', payload: cognitoUser });

          return history.push('/');
        }
      }
    } catch (err) {
      if (err.code === 'PasswordResetRequiredException') {
        dispatch({
          type: 'auth-challenge',
          payload: { user, email, challengeName: err.code }
        });
        return history.push('/change-password');
      }

      setAuthenticated(false, { expires: _expireCookie });
      return dispatch({ type: 'auth-error', payload: err.message });
    }
  };

  const completeNewPassword = async (data, history) => {
    const { password, code } = data;
    try {
      let cognitoUser =
        code && Auth.forgotPasswordSubmit
          ? await Auth.forgotPasswordSubmit(user.email, code, password)
          : await Auth.completeNewPassword(user, password);
      /*
        forgotPasswordSubmit does not return a user object
        need to have the user reauthenticate.
      */
      if (!cognitoUser) {
        setAuthenticated(false, { expires: _expireCookie });
        return history.push('/sign-in');
      }

      const { signInUserSession } = cognitoUser;
      const { idToken } = signInUserSession;
      setAuthenticated(true, { expires: idToken.exp });
      dispatch({ type: 'auth-success', payload: cognitoUser });
      return history.push('/');
    } catch (err) {
      setAuthenticated(false, { expires: _expireCookie });
      if (err.code === 'InvalidPasswordException') {
        return dispatch({ type: 'auth-error', payload: err.message });
      }
    }
  };

  const logout = async (history) => {
    dispatch({ type: 'auth-request' });
    localStorage.setItem('token', undefined);
    setAuthenticated(false, { expires: _expireCookie });
    try {
      await Auth.signOut();
      dispatch({ type: 'auth-sign-out', payload: initialState });
      return history.push('/sign-in');
    } catch (err) {
      return dispatch({ type: 'auth-error', payload: err.message });
    }
  };

  const authProviderValue = {
    ...state,
    authenticated,
    completeNewPassword,
    initiateLogin,
    logout
  };

  return (
    <AuthProvider value={authProviderValue}>{props.children}</AuthProvider>
  );
};

export default AppAuth;
