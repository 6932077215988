/* eslint eqeqeq: "off", no-unused-vars: "off" */
import React, { useEffect, useState, Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

import BWSchema from './form/validation-schema';

import SaveButton from '../../components/buttons/save-button';
import CircleProgress from '../../components/progress/circle';

import cleanBWContactForMutation from '../utils/cleanBWContactForMutation';
import GET_ITEMS from './queries/listItems';
import GET_ITEM from './queries/getitem';
import EDIT_ITEM from './mutations/editItem';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

import BWSoldTo from './form/sold-to';
import BWContacts from './form/contacts';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 350
  },
  textMultiline: {
    width: '100%',
    minHeight: '3em'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  formControl: {
    minWidth: 200,
    marginRight: 15
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  firstInput: {
    marginLeft: 0,
    paddingLeft: 0
  },
  formSection: {
    margin: '20px 0'
  },
  sectionItem: {
    marginTop: 0
  },
  errorText: {
    color: theme.palette.error.main,
    margin: 0
  }
}));

const mutationUpdate = (params) => {
  return {
    update(cache, { data }) {
      cache.writeQuery({
        query: GET_ITEM,
        variables: { uuid: params.uuid },
        data: { getBWItem: data.editContactItem }
      });

      try {
        const listCache = cache.readQuery({ query: GET_ITEMS });

        if (listCache && listCache.getAllBWItems) {
          const existingIndex = listCache.getAllBWItems.findIndex(
            (item) => item.uuid === data.editContactItem.uuid
          );

          listCache.getAllBWItems.splice(
            existingIndex,
            1,
            data.editContactItem
          );

          cache.writeQuery({
            query: GET_ITEMS,
            data: listCache
          });
        }
      } catch (err) {
        // page refresh can wipe the listCache which causes an error
        // when trying to access it.
      }
    }
  };
};

const EditPage = (props) => {
  const { match, history } = props;
  const { params } = match;
  const classes = useStyles();
  const [contact, setContact] = useState({});
  const [editItem] = useMutation(EDIT_ITEM, mutationUpdate(params));
  const { loading, error, data } = useQuery(GET_ITEM, {
    variables: { uuid: params.uuid }
  });

  useEffect(() => {
    if (data && data.getBWItem) {
      setContact(data.getBWItem);
    }
  }, [data]);

  if (Object.keys(contact).length === 0 || loading) {
    return <CircleProgress size="1em" />;
  }
  if (error) return <p>{error.message} :(</p>;

  const submitForm = async (data) => {
    data.contacts = data.contacts.map((item) => {
      return {
        ...item,
        phone: item.phone || null,
        name: item.name || null
      };
    });
    const _data = cleanBWContactForMutation(data);
    await editItem({
      variables: {
        input: _data
      }
    });

    history.push('/contacts');
  };

  return (
    <Fragment>
      <Typography variant="h1">Edit Branded Wholesaler</Typography>
      <div className="create-form">
        <Formik
          validationSchema={BWSchema}
          onSubmit={submitForm}
          initialValues={{ ...contact }}
        >
          {({
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="bw-form-row">
                  <FormControl className={classes.formControl}>
                    <TextField
                      className={classes.textField}
                      label="Branded Wholesaler Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bw_name}
                      name="bw_name"
                      type="text"
                    />
                  </FormControl>
                  <FormHelperText className={classes.errorText}>
                    {errors.bw_name}
                  </FormHelperText>
                </div>
                <div className={`bw-form-row ${classes.formSection}`}>
                  <BWSoldTo
                    data={values.sold_to}
                    classes={classes}
                    onChange={handleChange}
                    errors={errors.sold_to}
                  />
                </div>

                <div className={`bw-form-row ${classes.formSection}`}>
                  <BWContacts
                    data={values.contacts}
                    classes={classes}
                    onChange={handleChange}
                    errors={errors.contacts}
                  />
                </div>
                <SaveButton label="Save" onClick={() => handleSubmit(values)} />
              </form>
            );
          }}
        </Formik>
      </div>
    </Fragment>
  );
};

export default EditPage;
