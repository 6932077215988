import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-boost';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import Auth from './auth';
import SigninPage from './auth/signin';
import ChangePassword from './auth/change-password';
import DashboardContainer from './containers/dashboard';

import Header from './components/header';
import theme from './theme';
import './index.css';

const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  request: async (operation) => {
    const token = localStorage.getItem('token');

    operation.setContext({
      headers: {
        'authorization': token,
        'x-api-key': process.env.REACT_APP_API_KEY
      }
    });
  }
});

Amplify.configure({
  Auth: {
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    region: process.env.REACT_APP_AWS_DEFAULT_REGION
  }
});

function App() {
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <Auth>
          <ApolloProvider client={apolloClient}>
            <Router>
              <Header />
              <Switch>
                <Route
                  exact
                  path="/change-password"
                  render={(props) => <ChangePassword {...props} />}
                />
                <Route
                  exact
                  path="/sign-in"
                  render={(props) => <SigninPage {...props} />}
                />
                <Route path="/" component={DashboardContainer} />
              </Switch>
            </Router>
          </ApolloProvider>
        </Auth>
      </MuiThemeProvider>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
