import { gql } from 'apollo-boost';

export default gql`
  {
    getUpcTodoItems {
      keyIndex
      shortDescription
      longDescription
      allDescriptions
      nacsCode
      nacsCodeNote
      createdDate
      modifiedDate
    }
  }
`;
