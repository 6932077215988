/*
 * This function will take a conatact and clean up all __typename
 * attributes at the various levels of the object.
 *
 * @param {object} data - defined in schema
 */
const cleanUpcPluItemsForMutation = (data) => {
  const items = [...data];
  return items.map((item) => {
    item.nacsCode = Array.isArray(item.revisedNacs)
      ? item.revisedNacs
      : item.revisedNacs.replace(/[\s,]+/g, ',').split(',');
    if (item.nacsCodeNote && item.nacsCodeNote.length === 0) {
      delete item.nacsCodeNote;
    }
    delete item.revisedNacs;
    delete item.__typename;
    return item;
  });
};

export default cleanUpcPluItemsForMutation;
