import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import CircleProgress from '../../components/progress/circle';

const SearchPricebook = (props) => {
  const { setData, isUpc, query, setError } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (queryResult) => {
    setData(
      isUpc ? queryResult.getUpcItemsBySearch : queryResult.getPluItemsBySearch
    );
  };
  const handleError = (error) => {
    setError(error);
  };
  const [executeSearch, { loading }] = useLazyQuery(query, {
    onCompleted: handleSearch,
    onError: handleError
  });
  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <TextField
          id="standard-search"
          label={isUpc ? 'Enter UPC' : 'Enter description'}
          type="search"
          variant="outlined"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={() =>
            executeSearch({
              variables: { searchTerm }
            })
          }
          style={{ height: '57px' }}
        >
          {loading ? <CircleProgress size="1em" /> : 'Search'}
        </Button>
      </div>
    </>
  );
};

export default SearchPricebook;
