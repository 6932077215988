import { gql } from 'apollo-boost';

export default gql`
  mutation editContactItemMutation($input: EditContactListItemInput) {
    editContactItem(input: $input) {
      uuid
      bw_name
      sold_to
      contacts {
        email
        phone
        name
      }
    }
  }
`;
