import { gql } from 'apollo-boost';

export default gql`
  query getBWItemQuery($uuid: String!) {
    getBWItem(uuid: $uuid) {
      uuid
      bw_name
      contacts {
        email
        phone
        name
      }
      sold_to
    }
  }
`;
