import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BWContactList from '../bw-contact-list/view';
import BWContactCreate from '../bw-contact-list/create';
import BWContactEdit from '../bw-contact-list/edit';

import isAuthenticated from '../../auth/isAuthenticated';
import NotFound from '../../components/not-found';

const DashboardContainer = (props) => {
  return (
    <Switch>
      <Route exact path="/contacts" component={BWContactList} />
      <Route exact path="/contacts/edit/:uuid" component={BWContactEdit} />
      <Route exact path="/contacts/create" component={BWContactCreate} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default isAuthenticated(DashboardContainer);
