import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#262a82'
    },
    secondary: {
      main: '#2196f3'
    },
    error: {
      main: '#FF0000'
    }
  },
  typography: {
    fontFamily: ['"Open Sans"', 'Roboto', 'Arial', 'sans-serif'].join(','),
    fontSize: 16,
    h1: {
      fontSize: '32px',
      fontWeight: '600',
      marginBottom: '25px',
      fontFamily: ['"Open Sans"', 'Roboto', 'Arial', 'sans-serif'].join(',')
    },
    h2: {
      fontSize: '28px',
      fontWeight: '600'
    },
    h3: {
      fontSize: '20px',
      fontWeight: '600'
    },
    button: {
      fontWeight: 600
    }
  }
});

export default theme;
