const rules = {
  'admin-group': {
    static: ['contact-list:view']
  }
};

const checkPermissions = (rules, role, action, data) => {
  const permissions = rules[role];

  if (!permissions) {
    return false;
  }

  if (permissions.static && permissions.static.includes(action)) {
    return true;
  }

  return false;
};

const allowAction = (props) => {
  const { groups, perform } = props;

  if (groups && groups.length >= 0) {
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];

      if (checkPermissions(rules, group, perform)) {
        return props.yes();
      }
    }
  }

  return props.no();
};

export default allowAction;
