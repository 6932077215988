import { gql } from 'apollo-boost';

export default gql`
  query GetPluItemsBySearch($searchTerm: String!) {
    getPluItemsBySearch(searchTerm: $searchTerm) {
      keyIndex
      productGroupCode
      shortDescription
      longDescription
      allDescriptions
      nacsCode
      nacsCodeNote
      createdDate
      modifiedDate
    }
  }
`;
