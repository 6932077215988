import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import authContext from '../../contexts/authContext';

import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import './header.scss';

const useStyles = makeStyles((theme) => ({
  link: {
    'color': '#FFF',
    'cursor': 'pointer',
    'fontSize': '1em',
    '&:hover': {
      color: '#bababa'
    }
  },
  user: {
    display: 'flex',
    alignItems: 'center'
  },
  profileIcon: {
    marginLeft: '5px'
  }
}));

const Header = (props) => {
  // Todo make user menu a drop down instead of a popover
  const [menuAnchor, setMenuAnchor] = useState(null);
  const handleClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchor(null);
  };

  const { history } = props;
  const classes = useStyles();
  const context = useContext(authContext);
  const [currentUsername, setCurrentUsername] = useState('');
  const { authenticated, logout, user } = context;
  useEffect(() => {
    setCurrentUsername(
      user && user.attributes && user.attributes.email
        ? user.attributes.email
        : ''
    );
  }, [user]);

  const renderAccount = authenticated ? (
    <div className="nav">
      <div className="logout nav-item" onClick={() => logout(history)}>
        Log Out
      </div>
      <div
        className={`nav-item ${classes.link} ${classes.user}`}
        onClick={handleClick}
      >
        <p>{currentUsername}</p>
        <AccountCircleIcon fontSize="large" className={classes.profileIcon} />
      </div>
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>Account Settings</MenuItem>
      </Menu>
    </div>
  ) : (
    <div className="nav" />
  );

  return (
    <div className="header">
      <div className="logo-container"></div>
      {renderAccount}
    </div>
  );
};

export default withRouter(Header);
