import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AddButton from '../../../components/buttons/add-button';
import RemoveButton from '../../../components/buttons/remove-button';
import './sold-to.scss';

const BWSoldTo = (props) => {
  const { data, classes, onChange, errors } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const [indexToDelete, setIndexToDelete] = React.useState(null);
  const minRequiredError =
    errors && Object.keys(errors).length === 0
      ? `you must have at least 1 sold to`
      : null;

  return (
    <FieldArray
      name="sold_to"
      render={(arrayHelpers) => {
        return (
          <div className="bw-sold-to">
            <div className="sold-to-header">
              <Typography variant="h3">Sold To</Typography>
              <AddButton
                label="add item"
                onClick={() => arrayHelpers.push('')}
              />
            </div>
            <div className="sold-to-row">
              <FormHelperText className={`${classes.errorText} sold-to-row`}>
                {minRequiredError}
              </FormHelperText>
            </div>
            {data && data.length > 0
              ? data.map((item, index) => {
                  const errorText =
                    errors && errors[index]
                      ? errors[index].replace(`sold_to[${index}]`, 'sold to')
                      : null;
                  return (
                    <Fragment key={index}>
                      <div className="sold-to-row">
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={`${classes.firstInput} ${classes.sectionItem}`}
                            label="sold to"
                            name={`sold_to.${index}`}
                            margin="normal"
                            onChange={onChange}
                            type="text"
                            value={item}
                          />
                        </FormControl>
                        <RemoveButton
                          style={{ paddingTop: '1.6em' }}
                          onClick={() => {
                            setDialogOpen(true);
                            setIndexToDelete(index);
                          }}
                        />
                      </div>
                      <div className="sold-to-row" key={index}>
                        <FormHelperText
                          className={`${classes.errorText} sold-to-row`}
                        >
                          {errorText}
                        </FormHelperText>
                      </div>
                      <Dialog open={dialogOpen} onClose={handleDialogClose}>
                        <DialogTitle id="alert-dialog-title">
                          Are you sure you want to delete this sold to?
                        </DialogTitle>
                        <DialogActions>
                          <Button onClick={handleDialogClose} color="primary">
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              arrayHelpers.remove(indexToDelete);
                              handleDialogClose();
                            }}
                            color="primary"
                            autoFocus
                          >
                            Confirm Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Fragment>
                  );
                })
              : null}
          </div>
        );
      }}
    />
  );
};

export default BWSoldTo;
