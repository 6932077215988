import React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthConsumer } from '../contexts/authContext';

const UserIsAuthenticated = (Component, props) => {
  // eslint-disable-next-line react/display-name
  return class extends React.Component {
    render() {
      const { location } = this.props;
      const _from = location && location.pathname ? location.pathname : '/';

      return (
        <AuthConsumer>
          {(context) => {
            const { authenticated } = context;

            if (!authenticated) {
              return (
                <Redirect
                  to={{
                    pathname: '/sign-in',
                    state: { from: _from }
                  }}
                />
              );
            }

            return <Component {...this.props} auth={context} />;
          }}
        </AuthConsumer>
      );
    }
  };
};

export default UserIsAuthenticated;
