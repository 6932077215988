import { gql } from 'apollo-boost';

export default gql`
  {
    getAllBWItems {
      uuid
      bw_name
      sold_to
      contacts {
        email
      }
    }
  }
`;
