import React, { Fragment } from 'react';

import Typography from '@material-ui/core/Typography';

import './dashboard.scss';

const DashboardPage = (props) => {
  return (
    <Fragment>
      <Typography variant="h1">Dashboard</Typography>
    </Fragment>
  );
};

export default DashboardPage;
