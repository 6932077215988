import React from 'react';
import { Route, Switch } from 'react-router-dom';

import isAuthenticated from '../../auth/isAuthenticated';
import NotFound from '../../components/not-found';

import SiteMasterComponent from './site-master';

const SiteMasterContainer = (props) => {
  return (
    <Switch>
      <Route exact path="/site-master" component={SiteMasterComponent} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default isAuthenticated(SiteMasterContainer);
