import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import authContext from '../../contexts/authContext';

import './signin.scss';

const SignInPage = (props) => {
  const context = useContext(authContext);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorText, setErrorText] = useState();
  const { initiateLogin, authErrorText } = context;
  const { location } = props;

  useEffect(() => {
    if (authErrorText !== 'not authenticated') {
      setErrorText(authErrorText);
    }
  }, [authErrorText]);

  const handleChange = (event) => {
    event.preventDefault();
    const { currentTarget } = event;
    const { name, value } = currentTarget;

    switch (name) {
      case 'password':
        setPassword(value);
        break;
      case 'email':
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handleSignIn = async (event) => {
    const { history } = props;
    event.preventDefault();

    if (!email || !password) {
      return null;
    }
    initiateLogin({ email, password }, history);
  };

  if (context.authenticated) {
    // state is passed from isAuthenticated
    const { state } = location;
    const redirectTo = state && state.from ? state.from : '/';
    return <Redirect to={redirectTo} />;
  }

  return (
    <div className="signin">
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        value={email || ''}
        onChange={handleChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        onChange={handleChange}
        value={password || ''}
      />
      <div className="auth-error-text">{errorText}</div>
      <Button
        type="button"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSignIn}
      >
        Sign In
      </Button>
    </div>
  );
};

export default SignInPage;
