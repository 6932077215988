import { gql } from 'apollo-boost';

export default gql`
  query SiteSearchQuery($searchProp: String) {
    siteSearch(searchProp: $searchProp) {
      dealerNumber
      storeName
      customerName
      soldTo
      plezAdded
      pleoAdded
    }
  }
`;
