import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import CircleProgress from '../../components/progress/circle';
import SEARCH_ITEMS_QUERY from './queries/listItems';

const SearchSiteMaster = (props) => {
  const { setData, setError } = props;
  const [searchFilter, setSearchFilter] = useState('');
  const handleSearch = (queryResult) => {
    setData(data.siteSearch);
  };
  const handleError = (error) => {
    setError(error);
  };
  const [executeSearch, { loading, data }] = useLazyQuery(SEARCH_ITEMS_QUERY, {
    onCompleted: handleSearch,
    onError: handleError
  });

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <TextField
          id="standard-search"
          label="Enter BW or Dealer #"
          type="search"
          variant="outlined"
          onChange={(e) => setSearchFilter(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={() =>
            executeSearch({
              variables: { searchProp: searchFilter }
            })
          }
          disabled={loading}
          style={{ height: '57px' }}
        >
          {loading ? <CircleProgress size="1em" /> : 'Search'}
        </Button>
      </div>
    </>
  );
};

export default SearchSiteMaster;
