import * as Yup from 'yup';

const BWSchema = Yup.object().shape({
  bw_name: Yup.string().required('Branded wholesaler name is required'),
  sold_to: Yup.array().of(Yup.string().min(1).required()).required(1),
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string().email().min(1).required('email is required'),
        phone: Yup.string().nullable()
      })
    )
    .required(1)
});

export default BWSchema;
