import { gql } from 'apollo-boost';

export default gql`
  query GetUpcItemsBySearch($searchTerm: String!) {
    getUpcItemsBySearch(searchTerm: $searchTerm) {
      keyIndex
      shortDescription
      longDescription
      allDescriptions
      nacsCode
      nacsCodeNote
      createdDate
      modifiedDate
    }
  }
`;
