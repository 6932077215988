import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BWContactList from '../bw-contact-list';
import UPCListComponent from '../pricebook/upc-list';
import PLUListComponent from '../pricebook/plu-list';
import UPCMasterListComponent from '../pricebook/upc-master-list';
import PLUMasterListComponent from '../pricebook/plu-master-list';
import SiteMasterComponent from '../site-master';
import SideNavigation from '../../components/side-navigation';

import DashboardPage from './page.js';
import isAuthenticated from '../../auth/isAuthenticated';
import NotFound from '../../components/not-found';

import './dashboard.scss';

const DashboardContainer = (props) => {
  return (
    <div className="dashboard">
      <SideNavigation />
      <div className="content">
        <Switch>
          <Route exact path="/" component={DashboardPage} />
          <Route path="/contacts" component={BWContactList} />
          <Route path="/pricebook/upc" component={UPCListComponent} />
          <Route path="/pricebook/plu" component={PLUListComponent} />
          <Route
            path="/pricebook/upc-master"
            component={UPCMasterListComponent}
          />
          <Route
            path="/pricebook/plu-master"
            component={PLUMasterListComponent}
          />
          <Route path="/site-master" component={SiteMasterComponent} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};

export default isAuthenticated(DashboardContainer);
