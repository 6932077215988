import React from 'react';

const getViewBox = (name) => {
  switch (name) {
    case 'bars':
      return '0 0 97.33 84';
    case 'calculator':
      return '0 0 95 80.67';
    case 'dial':
      return '0 0 152.81 76.62';
    case 'gear':
      return '0 0 194.67 194.67';
    case 'triangle':
      return '0 0 48.88 96.39';
    case 'wrench':
      return '0 0 79.1 73.32';
    case 'add':
    case 'cancel':
    case 'circle-check':
    case 'pencil':
    case 'remove':
    case 'menu':
      return '0 0 24 24';
    default:
      return '0 0 32 32';
  }
};

const getPath = (name, props) => {
  switch (name) {
    case 'add':
      return (
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            {...props}
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
          />
        </g>
      );
    case 'bars':
      return (
        <g id="Layer_1-2" data-name="Layer 1">
          <rect {...props} y="37.8" width="27.33" height="46.2" />
          <rect {...props} x="35" y="20.53" width="27.33" height="63.47" />
          <rect {...props} x="70" width="27.33" height="84" />
        </g>
      );
    case 'calculator':
      return (
        <path
          {...props}
          d="M0,0V80.67H95V0ZM73,42.83H58.67V32.17H73Zm4-10.66H91V42.83H77Zm-4,27.5H58.67V49H73ZM77,49H91V59.67H77ZM21,49H36.33V59.67H21ZM17,59.67H4V49H17Zm4-27.5H36.33V42.83H21ZM17,42.83H4V32.17H17ZM40.33,59.67V49H54.67V59.67Zm0-16.84V32.17H54.67V42.83ZM36.33,66V76.67H21V66Zm4,0H54.67V76.67H40.33Zm18.34,0H73V76.67H58.67ZM4,66H17V76.67H4ZM91,76.67H77V66H91ZM4,4H91V26H4Z"
        />
      );
    case 'cancel':
      return (
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            {...props}
            d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
          />
        </g>
      );
    case 'circle-check':
      return (
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            {...props}
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
          />
        </g>
      );
    case 'dial':
      return (
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            {...props}
            d="M152.81,76.41a76.11,76.11,0,0,0-19.22-50.67L110.51,51.48a42.08,42.08,0,0,1,8.15,24.93Z"
          />
          <path
            {...props}
            d="M142.07,11.17,82,57.09A17,17,0,0,0,59.33,73.21a17.37,17.37,0,0,0,.34,3.41H93.14a17.37,17.37,0,0,0,.34-3.41,17,17,0,0,0-1.27-6.44Z"
          />
          <path
            {...props}
            d="M106.53,6.17A76.42,76.42,0,0,0,0,76.41H34.15A42.26,42.26,0,0,1,91.8,37.05a42.83,42.83,0,0,1,6.54,3.25l28.2-21.55A76.47,76.47,0,0,0,106.53,6.17Z"
          />
        </g>
      );
    case 'gear':
      return (
        <path
          {...props}
          d="M194.67,110V84.63l-29.06-8.58a71,71,0,0,0-5-11.92l14.49-26.64-18-18L130.54,34a70.14,70.14,0,0,0-11.92-5L110,0H84.63L76.05,29.06a70.14,70.14,0,0,0-11.92,5L37.49,19.53l-18,18L34,64.13a70.14,70.14,0,0,0-5,11.92L0,84.63V110l29.06,8.59a70.14,70.14,0,0,0,5,11.92L19.53,157.18l18,18,26.64-14.49a71,71,0,0,0,11.92,5l8.58,29.06H110l8.59-29.06a71,71,0,0,0,11.92-5l26.64,14.49,18-18-14.49-26.64a71,71,0,0,0,5-11.92Zm-97.34,30.8a43.5,43.5,0,1,1,43.5-43.5A43.5,43.5,0,0,1,97.33,140.83Z"
        />
      );
    case 'menu':
      return (
        <g id="Layer_1-2" data-name="Layer 1">
          <path fill="#626262" d="M0 0h24v24H0z" />
          <path {...props} d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
        </g>
      );
    case 'pencil':
      return (
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            {...props}
            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </g>
      );
    case 'remove':
      return (
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            {...props}
            d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          />
        </g>
      );
    case 'triangle':
      return (
        <path
          {...props}
          d="M7.08,2.24C3.18-1.66,0-.34,0,5.17V91.22c0,5.5,3.18,6.82,7.08,2.93L46,55.27a10,10,0,0,0,0-14.15Z"
        />
      );
    case 'wrench':
      return (
        <path
          {...props}
          d="M79,19.74l-10.5,10.5L53.31,26.17,49.24,11,60,.21a22.13,22.13,0,0,0-23.2,31.12L.77,62.18c-1.22,1-1,3.36.59,5.17l3.49,4.08c1.55,1.81,3.8,2.43,5,1.39L46.39,41.58A22.13,22.13,0,0,0,79,19.74Z"
        />
      );
    default:
      return <path />;
  }
};

const SVGIcon = ({
  name = '',
  style = {},
  fill = '#DDD',
  viewBox = '',
  width = '100%',
  className = '',
  height = '100%'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox || getViewBox(name)}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {getPath(name, { fill })}
  </svg>
);

export default SVGIcon;
