import React, { useState, useEffect, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import PeopleIcon from '@material-ui/icons/People';
import StoreIcon from '@material-ui/icons/Store';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import isAuthenticated from '../../auth/isAuthenticated';
import Can from '../../auth/userCanPerform';

import SVGIcon from '../../icons';
import './side-navigation.scss';

const drawerWidth = 'auto';
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    order: 0,
    position: 'relative',
    height: '100vh',
    marginRight: '20px'
  },
  drawerPaper: {
    background: '#565656',
    color: '#FFF',
    width: drawerWidth,
    position: 'unset'
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '55px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px'
  },
  icon: {
    minWidth: 0,
    marginRight: '15px'
  },
  noIcon: {
    paddingLeft: '55px'
  },
  normalCursor: {
    cursor: 'default'
  },
  appMenu: {
    marginBottom: '15px'
  },
  nestedFont: {
    fontSize: 10
  },
  divider: {
    borderTop: '1px solid white'
  },
  externalLink: {
    'color': '#FFF',
    'cursor': 'pointer',
    'fontSize': '1em',
    '&:hover': {
      color: '#bababa'
    }
  }
}));

const renderContactList = (props) => {
  const { auth, classes, activeLinkColor } = props;

  return Can({
    groups: auth.groups,
    perform: 'contact-list:view',
    /* eslint-disable-next-line react/display-name */
    yes: () => {
      return (
        <Fragment>
          <NavLink to="/contacts" activeClassName={activeLinkColor}>
            <ListItem button key="customers">
              <ListItemIcon className={classes.icon}>
                <PeopleIcon htmlColor="white" />
              </ListItemIcon>
              <ListItemText className="list-item-link" primary="BW Contact" />
            </ListItem>
          </NavLink>
          <NavLink to="/pricebook/upc" activeClassName={activeLinkColor}>
            <ListItem button key="pricebook">
              <ListItemIcon className={classes.icon}>
                <ShoppingCartIcon htmlColor="white" />
              </ListItemIcon>
              <ListItemText className="list-item-link" primary="Pricebook" />
            </ListItem>
          </NavLink>
          <List disablePadding>
            <NavLink to="/pricebook/upc" activeClassName={activeLinkColor}>
              <ListItem button>
                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: '15px' } }}
                  inset
                  className="list-item-link"
                  primary="UPC To-Do"
                />
              </ListItem>
            </NavLink>
            <NavLink to="/pricebook/plu" activeClassName={activeLinkColor}>
              <ListItem button>
                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: '15px' } }}
                  inset
                  className="list-item-link"
                  primary="PLU To-Do"
                />
              </ListItem>
            </NavLink>
            <NavLink
              to="/pricebook/upc-master"
              activeClassName={activeLinkColor}
            >
              <ListItem button>
                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: '15px' } }}
                  inset
                  className="list-item-link"
                  primary="UPC Master"
                />
              </ListItem>
            </NavLink>
            <NavLink
              to="/pricebook/plu-master"
              activeClassName={activeLinkColor}
            >
              <ListItem button>
                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: '15px' } }}
                  inset
                  className="list-item-link"
                  primary="PLU Master"
                />
              </ListItem>
            </NavLink>
          </List>
          <NavLink to="/site-master" activeClassName={activeLinkColor}>
            <ListItem button key="site-master">
              <ListItemIcon className={classes.icon}>
                <StoreIcon htmlColor="white" />
              </ListItemIcon>
              <ListItemText className="list-item-link" primary="Site Master" />
            </ListItem>
          </NavLink>
        </Fragment>
      );
    },
    no: () => null
  });
};

const SideNavigation = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { auth } = props;
  const _drawerClass = open ? classes.drawerOpen : classes.drawerClose;
  const activeLinkColor = 'active-link-color';
  const isSmallScreen = useMediaQuery('(max-width:500px)');
  const showContactList = renderContactList({ auth, classes, activeLinkColor });

  function handleDrawerToggle() {
    setOpen(open ? false : true);
  }

  useEffect(() => {
    if (isSmallScreen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isSmallScreen]);

  return (
    <Drawer
      className={`${classes.drawer} ${_drawerClass}`}
      variant="permanent"
      anchor="left"
      classes={{
        paper: `${classes.drawerPaper} ${_drawerClass}`
      }}
      open={open}
    >
      <List className={classes.appMenu}>
        <ListItem onClick={handleDrawerToggle} button key="menu">
          <ListItemIcon>
            <SVGIcon name="menu" width="24px" height="24px" />
          </ListItemIcon>
        </ListItem>
        <NavLink to="/" activeClassName={activeLinkColor}>
          <ListItem button key="dashboard">
            <ListItemIcon className={classes.icon}>
              <SVGIcon name="dial" width="27.42px" height="24px" />
            </ListItemIcon>
            <ListItemText className="list-item-link" primary="Dashboard" />
          </ListItem>
        </NavLink>
        {showContactList}
      </List>
    </Drawer>
  );
};

export default isAuthenticated(SideNavigation);
