import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './circle.scss';

const CircleProgress = (props) => {
  const { size } = props;

  return (
    <div className="progress-circle">
      <CircularProgress size={size} />
    </div>
  );
};

export default CircleProgress;
