import { gql } from 'apollo-boost';

export default gql`
  mutation editUpcItemMutation($input: [EditUpcItemInput]) {
    editUpcItems(input: $input) {
      keyIndex
      shortDescription
      longDescription
      allDescriptions
      nacsCode
      nacsCodeNote
      createdDate
      modifiedDate
    }
  }
`;
