import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AddButton from '../../../components/buttons/add-button';
import RemoveButton from '../../../components/buttons/remove-button';
import './contacts.scss';

const BWContacts = (props) => {
  const { data, classes, onChange, errors } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const [indexToDelete, setIndexToDelete] = React.useState(null);
  const minRequiredError =
    errors && Object.keys(errors).length === 0
      ? `you must have at least ${errors} contact`
      : null;

  return (
    <FieldArray
      name="contacts"
      render={(arrayHelpers) => {
        return (
          <div className="bw-contacts">
            <div className="contacts-header">
              <Typography variant="h3">Contacts</Typography>
              <AddButton
                label="add item"
                onClick={() => arrayHelpers.push({ email: '', phone: '' })}
              />
            </div>
            <div className="contacts-row">
              <FormHelperText className={`${classes.errorText} contacts-row`}>
                {minRequiredError}
              </FormHelperText>
            </div>
            {data && data.length > 0
              ? data.map((item, index) => {
                  const error = errors ? errors[index] : {};

                  if (error && error.email) {
                    error.email = error.email.replace(
                      `contacts[${index}].email`,
                      ''
                    );
                  }

                  if (error && error.phone) {
                    error.phone = error.phone.replace(
                      `contacts[${index}].phone`,
                      ''
                    );
                  }

                  return (
                    <Fragment key={`contact-${index}`}>
                      <div className="contacts-row">
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={`${classes.firstInput} ${classes.sectionItem}`}
                            label="name"
                            name={`contacts.${index}.name`}
                            margin="normal"
                            onChange={onChange}
                            type="text"
                            value={item.name || ''}
                          />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={`${classes.sectionItem}`}
                            label="email"
                            name={`contacts.${index}.email`}
                            margin="normal"
                            onChange={onChange}
                            type="text"
                            value={item.email || ''}
                          />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={`${classes.sectionItem}`}
                            label="phone"
                            name={`contacts.${index}.phone`}
                            margin="normal"
                            onChange={onChange}
                            type="text"
                            value={item.phone || ''}
                          />
                        </FormControl>
                        <RemoveButton
                          style={{ paddingTop: '1.6em' }}
                          onClick={() => {
                            setDialogOpen(true);
                            setIndexToDelete(index);
                          }}
                        />
                      </div>
                      <div className="contacts-row">
                        <FormHelperText
                          className={`${classes.errorText} contacts-row`}
                        >
                          {error && error.email}
                        </FormHelperText>
                        <FormHelperText
                          className={`${classes.errorText} contacts-row`}
                        >
                          {error && error.phone}
                        </FormHelperText>
                      </div>
                      <Dialog open={dialogOpen} onClose={handleDialogClose}>
                        <DialogTitle id="alert-dialog-title">
                          Are you sure you want to delete this sold to?
                        </DialogTitle>
                        <DialogActions>
                          <Button onClick={handleDialogClose} color="primary">
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              arrayHelpers.remove(indexToDelete);
                              handleDialogClose();
                            }}
                            color="primary"
                            autoFocus
                          >
                            Confirm Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Fragment>
                  );
                })
              : null}
          </div>
        );
      }}
    />
  );
};

export default BWContacts;
