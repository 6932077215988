import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import dayjs from 'dayjs';

// import SaveButton from '../../components/buttons/save-button';
import CircleProgress from '../../components/progress/circle';
import SearchPricebook from './search-pricebook';

import isAuthenticated from '../../auth/isAuthenticated';
// import GET_MASTER_UPC_ITEMS from './queries/listUpcMaster';
// import GET_TODO_UPC_ITEMS from './queries/listUpcTodo';
import EDIT_ITEMS from './mutations/editItems';
import cleanUpcPluItemsForMutation from '../utils/cleanUpcPluItemsForMutation';
import SEARCH_UPC_MASTER_QUERY from './queries/listUpcItems';

import MUIDataTable from 'mui-datatables';
import {
  Button,
  createMuiTheme,
  FormControlLabel,
  MuiThemeProvider,
  TextField
} from '@material-ui/core';

import './nacs-button.scss';

// keyIndex,
// shortDescription,
// longDescription,
// nacsCode,
// nacsCodeNote,
// createdDate,
// modifiedDate,
const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          paddingTop: '0',
          paddingBottom: '0'
        }
      }
    }
  });

const options = {
  selectableRows: false,
  setRowProps: () => {
    return {
      className: 'data-row'
    };
  },
  rowsPerPageOptions: ['50', '100'],
  rowsPerPage: 50
};

const updateObjFromRowData = (row) => {
  const updateUpcObject = {
    keyIndex: row.keyIndex,
    longDescription: row.longDescription,
    nacsCode: row.nacsCode,
    revisedNacs: row.revisedNacs,
    nacsCodeNote: row.nacsCodeNote
  };

  return updateUpcObject;
};

// const getDataByRoute = path => {
//   let query;

//   switch (path) {
//     case '/pricebook/upc':
//       query = GET_TODO_UPC_ITEMS;
//       break;
//     case '/pricebook/upc-master':
//       query = GET_MASTER_UPC_ITEMS;
//       break;
//     default:
//       query = GET_TODO_UPC_ITEMS;
//   }

//   return query;
// };

const UPCMasterListComponent = (props) => {
  // const { location } = props;
  // const query = getDataByRoute(location.pathname);
  // TODO: Split up queries for Todo/Master of UPC/PLU
  // TODO: Check route to define which data to use
  // const { loading, error, data } = useQuery(query);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mutateUpcItems] = useMutation(EDIT_ITEMS);

  const saveUpdates = async () => {
    setLoading(true);

    const updates = _.filter(rows, (row) => row.revisedNacs);
    const dbUpdates = _.each(updates, (row) => updateObjFromRowData(row));

    // send dbUpdates to API
    const _data = cleanUpcPluItemsForMutation(dbUpdates);

    // Ensure all NACS codes are 3 digits, otherwise do not send to DB - CM
    const filteredData = _data.filter((item) => {
      const regex = /^[0-9]{3,3}$/;
      return item.nacsCode.every((code) => regex.test(code));
    });

    if (filteredData.length > 0) {
      await mutateUpcItems({
        variables: {
          input: _data
        }
      });
      setLoading(false);
    } else {
      setLoading(false);
      return;
    }
  };

  const updateTableData = (keyIndex, value, key) => {
    const row = rows.find((row) => row.keyIndex === keyIndex);
    const rowIndex = rows.findIndex((row) => row.keyIndex === keyIndex);

    row[key] = value;
    rows.splice(rowIndex, 1, row);
    setRows([...rows]);
  };

  if (loading) return <CircleProgress size="1em" />;
  // if (error) return <p>{error.message} :(</p>;

  const columns = [
    {
      name: 'createdDate',
      label: 'Created Date',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return dayjs(value).format('MM-DD-YYYY');
        }
      }
    },
    {
      name: 'keyIndex',
      label: 'UPC',
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/search?q=UPC ${value}`}
            >
              {value}
            </a>
          );
        }
      }
    },
    {
      name: 'longDescription',
      label: 'Description',
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/search?q=${value}`}
            >
              {value}
            </a>
          );
        }
      }
    },
    {
      name: 'nacsCode',
      label: 'NACS Code',
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowData } = tableMeta;
          const printValue = Array.isArray(value) ? value.join(', ') : value;
          return (
            <span>
              {printValue}
              <Button
                variant="outlined"
                size="small"
                className={'nacs-btn'}
                onClick={(event) => {
                  updateTableData(rowData[1], value, 'revisedNacs');
                }}
              >
                Accept &gt;
              </Button>
            </span>
          );
        }
      }
    },
    {
      name: 'revisedNacs',
      label: 'Revised NACS Code',
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowData } = tableMeta;
          if (!value) value = '';
          return (
            <FormControlLabel
              name={'revisedNacs'}
              value={value}
              control={<TextField value={value} />}
              onChange={(event) => {
                updateValue(event.target.value);
              }}
              onBlur={(event) => {
                updateTableData(rowData[1], event.target.value, 'revisedNacs');
              }}
            />
          );
        }
      }
    },
    {
      name: 'nacsCodeNote',
      label: 'Comment',
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowData } = tableMeta;
          return (
            <FormControlLabel
              name={'nacsCodeNote'}
              value={value}
              control={<TextField value={value} style={{ width: '100%' }} />}
              style={{ width: '100%' }}
              onChange={(event) => {
                if (
                  event.target.value.length < 501 &&
                  event.target.value.indexOf('|') === -1
                ) {
                  updateValue(event.target.value);
                }
              }}
              onBlur={(event) => {
                updateTableData(rowData[1], event.target.value, 'nacsCodeNote');
              }}
            />
          );
        }
      }
    }
  ];

  return (
    <Fragment>
      <SearchPricebook
        setData={setRows}
        isUpc={true}
        query={SEARCH_UPC_MASTER_QUERY}
      ></SearchPricebook>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          className="upc-table"
          title={'UPC Master List'}
          data={rows}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
      <div className="sticky-footer">
        <Button onClick={saveUpdates} variant="contained" size="large">
          {loading ? <CircleProgress size="1em" /> : 'Save'}
        </Button>
      </div>
    </Fragment>
  );
};

export default isAuthenticated(UPCMasterListComponent);
