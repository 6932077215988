import React, { Fragment, useState } from 'react';
import SearchSiteMaster from './search-site-master';

import isAuthenticated from '../../auth/isAuthenticated';
import SEARCH_ITEMS_QUERY from './queries/listItems';

import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core';

const options = {
  selectableRows: false,
  setRowProps: () => {
    return {
      className: 'data-row'
    };
  },
  rowsPerPageOptions: ['500'],
  rowsPerPage: 500
};

const columns = [
  {
    name: 'dealerNumber',
    label: 'Dealer #',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'storeName',
    label: 'Store Name',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'customerName',
    label: 'BW',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'soldTo',
    label: 'Sold To',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'plezAdded',
    label: 'PLEZ',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'pleoAdded',
    label: 'PLEO',
    options: {
      filter: true,
      sort: true
    }
  }
];

const SiteMasterComponent = (props) => {
  //   const { auth, history } = props;
  const [rows, setRows] = useState([]);
  //   const showContactList = renderContactList({ history, auth, rows });
  const [error, setError] = useState(null);

  if (error) return <p>{error.message} :(</p>;

  return (
    <Fragment>
      <SearchSiteMaster
        setData={setRows}
        setError={setError}
        isUpc={true}
        searchKey={'upc'}
        query={SEARCH_ITEMS_QUERY}
      ></SearchSiteMaster>
      <MuiThemeProvider>
        <MUIDataTable
          title={'Site Master'}
          data={rows}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
      <div className="sticky-footer"></div>
    </Fragment>
  );
};

export default isAuthenticated(SiteMasterComponent);
