import gql from 'graphql-tag';

var query = gql`
  mutation createContactItemMutation($input: CreateContactListItemInput!) {
    createContactItem(input: $input) {
      uuid
      bw_name
      sold_to
      contacts {
        email
        phone
        name
      }
    }
  }
`;

export default query;
