import { gql } from 'apollo-boost';

export default gql`
  mutation editPluItemMutation($input: [EditPluItemInput]) {
    editPluItems(input: $input) {
      keyIndex
      shortDescription
      productGroupCode
      longDescription
      allDescriptions
      nacsCode
      nacsCodeNote
      createdDate
      modifiedDate
    }
  }
`;
