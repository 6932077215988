/*
 * This function will take a conatact and clean up all __typename
 * attributes at the various levels of the object.
 *
 * @param {object} data - defined in schema
 */
const cleanBWContactForMutation = (data) => {
  delete data.__typename;
  data.contacts &&
    data.contacts.map((item) => {
      delete item.__typename;
      return item;
    });

  return data;
};

export default cleanBWContactForMutation;
